<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate'
import { htmlEntities } from '../../../../../../utils/commonUtils'

function getCheckIdList (data) {
  return htmlEntities(data.check_id)
}

export default {
  name: 'ValidatedNotPassingCheckPremep',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Blocking_editable_check_list',
          value: (alert) => {
            if (!alert.data.blocking_editable_list) {
              return ''
            }
            return getCheckIdList(alert.data.blocking_editable_list)
          }
        },
        {
          label: 'Blocking_non_editable_check_list',
          value: (alert) => {
            if (!alert.data.blocking_non_editable_list) {
              return ''
            }
            return getCheckIdList(alert.data.blocking_non_editable_list)
          }
        },
        {
          label: 'Non_blocking_editable_check_list',
          value: (alert) => {
            if (!alert.data.non_blocking_editable_list) {
              return ''
            }
            return getCheckIdList(alert.data.non_blocking_editable_list)
          }
        }
      ],
      settingsData: [],
      algoStrategyData: []
    }
  }
}
</script>

<style scoped>

</style>
